import React from "react";
import { withRouter } from 'react-router-dom'
import DigitacaoService from '../app/service/digitacaoService'
import * as message from "../components/toastr";
// import '../../node_modules/video-react/dist/video-react.css';
import FrameDigitacao from "../components/frameDigitacao";
import SchoolIcon from '@mui/icons-material/School';
import { Modal, Row } from "react-bootstrap";
import "./css/digitacao.css"
import ComputerIcon from '@mui/icons-material/Computer';


class Digitacao extends React.Component {

    constructor() {
        super();       
        this.service = new DigitacaoService();  
    }

    state = {

        urlAula: "",
        digitacao : null,
        modalTutorial: false

    }

    async componentDidMount() {
       
       await this.buscarAulaAtual();

    }

    
    buscarAulaAtual = async () => {
        await this.service.buscarAulaAtual()
        .then(response => {
           
           
            if(response.data.aula === 1 && response.data.tutorial < 1){
            
                this.props.history.push('/tutorialDig');
            
            }else if (response.data.aula === 17 && response.data.tutorial < 2){

                this.props.history.push('/tutorialDig');

            }else if (response.data.aula === 30 && response.data.tutorial < 3){

                this.props.history.push('/tutorialDig');

            }

           
             this.setState({urlAula : `./digitacao/Aula${response.data.aula}/index.html`})
             this.setState({digitacao : response.data})
             this.setState({maximoErro : response.data.maximoerro} , () => {})
             this.setState({maximodigitacao : response.data.maximodigitacao} , () => {})



        }).catch(erro => {
            console.error(erro.response)
        })
    }
  
    aprovadoDigitacao = () => {
        this.service.avancarAula()
        .then(response => {
            
        }).catch(erro => {
            console.error(erro.response.data)
            message.mensagemErro("Erro")
        })

    } 


    setarPontuacao = () => {
        //buscar a quantidade de erros e acertos na tabela parametros do cliente
        if(this.state.maximoErro > 0 && this.state.maximodigitacao > 0){
            
            window.frames['frameDig'].setarPontuacao(this.state.maximoErro, this.state.maximodigitacao);

        }else{

            window.frames['frameDig'].setarPontuacao(15, 150);
        }

    }

    render() {
        return (
            <>
                <FrameDigitacao 
                    buscarAulaAtual={() => {this.buscarAulaAtual()}}
                    aprovadoDigitacao={() => {this.aprovadoDigitacao()}}
                    history={this.props.history}
                    urlAula={this.state.urlAula}
                    setarPontuacao={() => {this.setarPontuacao()}}
                />          
                <div style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    height: "45px",
                    display: "flex",
                    justifyContent: "center",
                }} className="btn btn-primary rounded-pill" onClick={() => {this.setState({modalTutorial: true})}}>
                    <b className="py-1">
                        Tutoriais
                        <SchoolIcon className="mx-1" />
                    </b>
                </div>

                <Modal 
                    show={this.state.modalTutorial}
                    onHide={() => {this.setState({modalTutorial: false})}}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Tutoriais Digitação
                            <SchoolIcon className="mx-1" />
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="px-4">
                        <div className="w-100 text-center">
                            <b className="text-danger">*Aviso: Assistir ao tutorial agora fará com que sua aula volte do começo.</b>
                        </div>
                        <Row 
                            className="tutorial-line"
                            onClick={() => {this.props.history.push('/tutorialDig?tutorial=1')}}
                        ><b>Tutorial 1</b> Postura e informações iniciais</Row>
                        <Row 
                            className="tutorial-line"
                            onClick={() => {this.props.history.push('/tutorialDig?tutorial=2')}}
                        ><b>Tutorial 2</b> Interface, regras e orientações das aulas</Row>
                        <Row 
                            className="tutorial-line"
                            onClick={() => {this.props.history.push('/tutorialDig?tutorial=3')}}
                        ><b>Tutorial 3</b> Acentuação</Row>
                    </Modal.Body>

                </Modal>
            </>
        )
    }
}

export default withRouter(Digitacao);