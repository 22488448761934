import ApiService from "../apiservice";
import * as message from "../../components/toastr"; 

class GerarCertificadoService extends ApiService{

   
    constructor(){
        super('/GerarCertificado')
    }

    getInfo(idCurso){
        return this.get(`/?idCurso=${idCurso}`)
    }

    getPermition() {
        return this.get(`/contem`)
    }

    async postCertificado(idCurso){

        const data = new URLSearchParams();
        data.append('idCurso', idCurso);

        const config = {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem("access_token").replace('"', '').replace('"', '')}`
            },
            body: data,
        }

        try {

            return await fetch(process.env.REACT_APP_API_URL + 'GerarCertificado/gerar', config)
            .then(async (response) => {
                const contentType = response.headers.get("content-type");

                let blob = undefined;
                if(contentType.includes("application/pdf")){
                    try {
                        blob = await response.blob();
                    } catch {
                        blob = undefined;  
                    }
                }

                let text = undefined
                if(contentType.includes("application/text")){
                    try {
                        text = await response.text();
                    } catch (e) {
                        text = undefined;  
                    }
                }

                return {
                    status: response.status,
                    error: text,
                    blob: blob,
                }
            })
            .then(async (data) => {

                let url = undefined;
                if(data.blob){
                    url = URL.createObjectURL(data.blob)
                }

                if(data.error){
                    message.mensagemErro(data.error)
                }

                return {
                    status: data.status,
                    error: data.error,
                    url: url,
                }
                
            })

        }catch (e) {
            console.log("Erro ao gerar o certificado")
            return null
        }

    }

}

export default GerarCertificadoService