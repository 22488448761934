
import { Modal, Row } from "react-bootstrap";
import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ProgressBar from 'react-bootstrap/ProgressBar';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import gerarCertificadoService from "../../app/service/gerarCertificadoService";
import * as message from "../../components/toastr";


class ModalMeusCursos extends React.Component {

    state = {
        cursoId: null,
        cursoDescricao: null,
        podeGerarCertificado: null,
        timeoutGerarCertificado: false,
    }

    fetchData = async () => {
        const gerarCertificadoSrv = new gerarCertificadoService();
        const podeGerar = (await gerarCertificadoSrv.getPermition())?.data;
        this.setState({podeGerarCertificado: podeGerar});
    }

    isMobile = () => {
        if (window.innerWidth < 640 || window.innerHeight < 480) {
            return true;
        } else if (window.innerWidth < 1024 || window.innerHeight < 768) {
            // talvez seja uma boa usar versão pra tablet
            return true;
        } else {
            return false;
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    gerarCertificado = async (idCurso) => {

        this.setState({timeoutGerarCertificado: true});
        setTimeout(() => {
            this.setState({timeoutGerarCertificado: false});
        }, 5000)

        const gerarCertificadoSrv = new gerarCertificadoService();
        const response = await gerarCertificadoSrv.postCertificado(idCurso);
        const url = response.url;
        if(response.status === 200){
            try {
                // Criar um link para download
                const link = document.createElement('a');
                link.href = url;
                link.download = `Certificado-${JSON.parse(sessionStorage.getItem("_usuario_logado")).nome}.pdf`; // Nome do arquivo no download
                document.body.appendChild(link);
                link.click();

                // Limpar o objeto URL para liberar memória
                URL.revokeObjectURL(url);
                document.body.removeChild(link);
                message.mensagemSucesso("O donwload foi iniciado!")
            }catch(e) {
                console.error("Erro ao gerar o certificado");
            }
        }
    }

    render() {

        return (
            <>  
            
                <Modal
                    onHide={this.props.onHide}
                    show={this.props.show}
                    size="xl"
                    fullscreen={this.isMobile()}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        Meus Cursos
                        <AnalyticsIcon color="action" />
                    </Modal.Header>
                    <Modal.Body>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 100 }} aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Ordem</TableCell>
                                        <TableCell>Curso</TableCell>
                                        <TableCell>Aula Atual</TableCell>
                                        <TableCell>Total de Aulas</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Andamento</TableCell>
                                        {this.state.podeGerarCertificado && <TableCell>Ações</TableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.props.cursos?.length <= 0 ?
                                        <TableRow>
                                            <TableCell align="center">Nenhum curso encontrado!</TableCell>
                                        </TableRow>

                                        :
                                        this.props.cursos.map((row) => (
                                            <TableRow
                                                key={row.codigoCurso}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.ordem}
                                                </TableCell>
                                                <TableCell component="th" scope="row" >
                                                    {row.descricao}
                                                </TableCell>
                                                <TableCell >{row.aulaAtual}</TableCell>
                                                <TableCell >{row.totalDeAulas}</TableCell>
                                                <TableCell>{row.status}</TableCell>
                                                <TableCell>
                                                    {row.andamento === 100 ?
                                                        <ProgressBar animated now={row.andamento} label={`${row.andamento}%`} variant="success" />
                                                        :
                                                        <ProgressBar animated now={row.andamento} label={`${row.andamento}%`} />
                                                    }


                                                </TableCell>
                                                {this.state.podeGerarCertificado && 
                                                    <TableCell >
                                                        {row.status === "Concluído" && !this.state.timeoutGerarCertificado ? 
                                                            <button className="btn btn-warning" onClick={() => {this.gerarCertificado(row.idCurso)}}><CardMembershipIcon />Certificado</button>
                                                            :
                                                            <button className="btn btn-secondary" style={{opacity: .25}} disabled><CardMembershipIcon />Certificado</button>
                                                        }
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </Modal.Body>
                    <Modal.Footer>
                        <Row>
                            {this.props.cursos.length > 0 ?
                                <>
                                    Andamento da Matrícula {`${this.props.porcentagemMat}%`}
                                </>
                                :
                                <>
                                </>
                            }

                        </Row>
                    </Modal.Footer>

                </Modal>
            </>
        )
    }

}

export default ModalMeusCursos