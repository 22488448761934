import { Modal, Accordion } from "react-bootstrap";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { withRouter } from "react-router-dom";
import Grid from "@mui/material/Grid";
import NotasExerciciosExtrasService from "../../app/service/notasExerciciosExtras";
import CircularProgress from "@mui/material/CircularProgress";
import NotasService from "../../app/service/notas";
import { mensagemAlert } from "../../components/toastr";

class ModalExerciciosDigitais extends React.Component {
  state = { notas: [], carregando: false };

  constructor() {
    super();
    this.service = new NotasExerciciosExtrasService();
    this.serviceNotas = new NotasService();
  }

  acessar = (row) => {
    //verificar se a interatividade já foi feita
    this.serviceNotas
      .verificaSePossuiNota(row.id, row.aula)
      .then((response) => {
        if (response.data === true) {
          this.props.history.push("/playerExerciciosDigitais");
        } else {
          mensagemAlert("Você precisa realizar a interatividade!");
        }
      })
      .catch((erro) => {
        console.error(erro.data);
      });
  };

  buscarNotas = (curso) => {
    this.setState({ carregando: true });

    this.service
      .buscarNotas(curso)
      .then((response) => {
        this.setState({ notas: response.data });
        this.setState({ carregando: false });
      })
      .catch((erro) => {
        console.error(erro.response);
        this.setState({ carregando: false });
      });
  };

  render() {
    return (
      <Modal
        onHide={this.props.onHide}
        show={this.props.show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          Exercícios Digitais
          <AddTaskIcon color="action" />
        </Modal.Header>
        <Modal.Body>
          {this.props.exercicios?.length <= 0 ? (
            <>Nada encontrado</>
          ) : (
            <Accordion defaultActiveKey="0">
              {this.props.exercicios.map((row, index) => (
                <Accordion.Item
                  eventKey={index}
                  key={index}
                  onClick={() => this.buscarNotas(row)}
                >
                  <Accordion.Header>
                    Aula {row.aula}
                    {index === this.props.exercicios?.length - 1 ? (
                      <div
                        onClick={() => {
                          this.acessar(row);
                        }}
                        style={{ marginLeft: "50%" }}
                        className="btn btn-primary"
                      >
                        Acessar
                      </div>
                    ) : (
                      <></>
                    )}
                  </Accordion.Header>
                  <Accordion.Body>
                    <Grid>
                      {this.state.carregando ? (
                        <center>
                          {" "}
                          <CircularProgress />
                        </center>
                      ) : (
                        <>
                          <TableContainer
                            md={{ maxHeight: 400 }}
                            sx={{ maxHeight: 300 }}
                            sm={{ maxHeight: 400 }}
                            xs={{ maxHeight: 500 }}
                          >
                            <Table
                              aria-label="simple table"
                              sx={{ minWidth: 50 }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">Data</TableCell>
                                  <TableCell align="center">Nota</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.notas.size === 0 &&
                                this.state.carregando === false ? (
                                  <>Carregando...</>
                                ) : (
                                  <>
                                    {this.state.notas.map((row2, index) => (
                                      <TableRow
                                        key={index}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell align="center">
                                          {new Date(
                                            row2.data
                                          ).toLocaleDateString()}
                                        </TableCell>
                                        <TableCell align="center">
                                          {row2.nota}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </>
                      )}
                    </Grid>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

export default withRouter(ModalExerciciosDigitais);
