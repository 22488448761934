import React from "react";
import { withRouter } from 'react-router-dom';
import DigitacaoService from '../app/service/digitacaoService'
import LogoutIcon from '@mui/icons-material/Logout';
import "./css/digitacao.css"
//import MatriculaService from "../app/service/matriculaService";

class TutorialDigitacao extends React.Component {

    constructor() {
        super()
        this.service = new DigitacaoService();      
        this.gravou = false;
       
    }

    state = {
        tutorialEspecifico: null,
        dadosDigitacao: null
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(this.props.location.search);
        let params = {};
        for(let param of searchParams) {
            params[param[0]] = param[1];
        }
        if(params.tutorial){
            this.setState({tutorialEspecifico: params.tutorial})
        }
        this.buscarDigitacao();
    }

    handleStateChange(state) {
        if (this.gravou === false) {     
            if(this.state.tutorialEspecifico == null){
                this.gravarTutorial();
            }
            this.gravou = true;
        }
    }

    gravarTutorial = () => {

        this.service.gravarTutorial(this.state.dadosDigitacao.tutorial)
            .then(response => {
                this.props.history.push('/digitacao');
            }).catch(erro => {
                console.error(erro.response.data)
            })

    }

    buscarDigitacao = async() => {
        await this.service.buscarAulaAtual()
            .then(response => {
              
                this.setState({ dadosDigitacao: response.data })
                
                if(this.state.tutorialEspecifico == null){

                    if (response.data.tutorial === 0) {

                        
                        this.urlTutorial = "digitacao/videos/tutorial1.mp4";

                    } else if (response.data.tutorial !== 2) {

                        
                        this.urlTutorial = "digitacao/videos/tutorial2.mp4";

                    } else if (response.data.tutorial !== 3) {

                        
                        this.urlTutorial = "digitacao/videos/tutoacento.mp4";
                    }

                } else if (this.state.tutorialEspecifico == 1) {
                    this.urlTutorial = "digitacao/videos/tutorial1.mp4";
                } else if (this.state.tutorialEspecifico == 2) {
                    this.urlTutorial = "digitacao/videos/tutorial2.mp4";
                } else if (this.state.tutorialEspecifico == 3) {   
                    this.urlTutorial = "digitacao/videos/tutoacento.mp4";
                }

            }).catch(erro => {
                console.error(erro.response.data)
            })

    }

    render() {
        
        return (
            <div className="d-flex flex-column align-items-center justify-content-center" style={{maxHeight: "100vh"}}>
                {this.urlTutorial && 
                    <video className="ratio ratio16x9" controls controlsList="noremoteplayback nodownload noplaybackrate nofoobar notimeline" style={{maxHeight: "100vh"}} onEnded={() => this.handleStateChange()}>
                        <source src={this.urlTutorial} type="video/mp4" />
                        <p className="text-light">
                            O seu navegador não tem suporte a vídeo HTML. Em vez disso, aqui está
                            <a href={this.urlTutorial} download={this.urlTutorial}>o link do vídeo</a>.
                        </p>
                    </video>
                }

                {this.state.tutorialEspecifico != null && 
                    <div
                        style={{
                            position: "fixed",
                            top: "10px",
                            right: "10px",
                            height: "45px",
                            display: "flex",
                            justifyContent: "center",
                            zIndex: 100,
                        }} 
                        className="btn btn-primary rounded-pill"
                    >
                        <b className="py-1" onClick={() => {this.props.history.push('/digitacao');}}>
                            Voltar
                            <LogoutIcon className="mx-1" />
                        </b>
                    </div>
                }

            </div>
        )
    
    }

}

export default withRouter(TutorialDigitacao)