import React, { useEffect, useRef, useState } from "react";
import { Modal, Row } from "react-bootstrap";
import gerarCertificadoService from "../../app/service/gerarCertificadoService";
import * as message from "../../components/toastr"; 
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import LocalParticles from "../../components/ConfettiParticles"
import "../css/certificate.css"

function ModalConclusaoCurso(props) {

  const [container, setContainer] = useState(null);
  const [, setIsLoading] = useState(true);
  const [podeGerar, setPodeGerar] = useState(false);
  const [deactiveBtn, setDeactiveBtn] = useState(false);
  const containerRef = useRef(null);
  useEffect(() => {
    const fetchData = async () => {
        const gerarCertificadoSrv = new gerarCertificadoService();
        setPodeGerar((await gerarCertificadoSrv.getPermition())?.data);
    }
    fetchData();
  }, [])

  async function gerarCertificado(idCurso) {

    setDeactiveBtn(true);
    setTimeout(() => {
      setDeactiveBtn(false);
    }, 5000);

    const gerarCertificadoSrv = new gerarCertificadoService();
    const response = await gerarCertificadoSrv.postCertificado(idCurso);
    const url = response.url;
    if(response.status === 200){
      try {
          // Criar um link para download
          const link = document.createElement('a');
          link.href = url;
          link.download = `Certificado-${JSON.parse(sessionStorage.getItem("_usuario_logado")).nome}.pdf`; // Nome do arquivo no download
          document.body.appendChild(link);
          link.click();

          // Limpar o objeto URL para liberar memória
          URL.revokeObjectURL(url);
          document.body.removeChild(link);
          message.mensagemSucesso("O donwload foi iniciado!")
      }catch(e) {
          console.error("Erro ao gerar o certificado");
      }
    }

  }

  useEffect(() => {
    const loadParticles = async () => {
      try {
        // Chama a função LocalParticles e aguarda a promessa
        const containerResult = await LocalParticles("confetti", {});
        setContainer(containerResult); // Salva o container no estado
        if (container && containerRef.current) {
          // O confete já foi carregado, agora o loading pode ser removido
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Erro ao carregar partículas");
      } finally {
        setIsLoading(false); // Marca que o carregamento foi finalizado
      }
    };

    loadParticles();
  }, []);

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Curso Concluído!
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-0">
          <Row>
            <img src="conclusao.jpg" alt="conclusão" loading="lazy" />
          </Row>
          {podeGerar && 
            <div className="d-flex flex-row aling-items-center justify-content-center" style={{height: "0px"}}>
              <button
                style={{
                  translate: window.innerWidth > 980 ? "0px -250%" : window.innerWidth > 480 ? "0px -170%" : "0px 10%",
                  height: "40px",
                }}
                disabled={deactiveBtn}
                className="certificate-button"
                onClick={() => gerarCertificado(JSON.parse(sessionStorage.getItem("_curso_atual")).id)}>
                  Baixar Certificado
                  <CardMembershipIcon style={{marginLeft: "5px"}} />
              </button>
            </div>
          }
        </Modal.Body>
      </Modal>
      <div 
        ref={containerRef} 
        id="confetti" 
        style={{ width: "100%", height: "100%", position: "relative", zIndex: 1100 }}
      ></div>
      <div className="py-2"></div>
    </>
  )
}

export default ModalConclusaoCurso;